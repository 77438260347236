<template>
    <div class="team">
        <div class="team-body">
            <div class="team-head">
                <img :src="roomInfo.cover" style="float: left; width: 340px; height: 196px; margin-right: 20px">
                <div>
                    <div style="font-size: 22px; font-weight: 600; margin: 10px 0">
                        {{roomInfo.name}}
                    </div>
                    <div style="font-size: 14px" v-if="roomInfo.address && roomInfo.industry.length > 0">
                        {{roomInfo.address.cityName[1]}} <span style="margin: 0 10px">|</span> <span v-for="(item,index) in roomInfo.industry" :key="index" style="margin-right: 4px">{{item.name}}</span>
                    </div>
                    <div style="margin-top: 10px; font-size: 16px">
                        专家团队简介：{{roomInfo.detail}}
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="column-head">
                    <div class="column-label">热门话题</div>
                    <div class="column-btns">
                        <el-select v-model="value" placeholder="行业分类" size="small" class="input-place">
                            <el-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="fresh hand op" style="width: 98px;height: 33px;line-height: 33px;border-radius: 5px;border: 1px solid #2970FF;text-align: center;color: #2970FF;font-size: 14px;" @click="getHotTopicList"><i class="el-icon-refresh-right"/> 筛选一下</div>
                    </div>
                </div>
                <div
                        style="padding: 20px 30px;box-sizing: border-box;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;">
                    <div style="overflow:hidden;width:100%;margin:20px 2px;">
                        <div v-for="(item, index) in hotTopicList" :key="index" @click="$router.push('/expert/space/' + item.uuid)">
                            <div v-if="item" style="min-height: 20px;line-height: 20px; color: #FFFFFF; float: left;text-align: center;margin:5px;word-wrap:break-word;padding: 6px 10px; border-radius:5px;"
                                 class="hand op sc" :style="{background:color(index+1)}">{{item.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column" style="margin-top: 10px;">
                <div class="column-head">
                    <div class="column-label">团队专家</div>
                </div>
                <div style="padding:20px 28px 10px;display: flex;align-items: center;flex-wrap: wrap;">
                    <div style="width: 280px;height: 350px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;padding: 20px 20px 0;box-sizing: border-box;margin-left:5px" v-for="item in expertList" :key="item.id">
                        <div style="overflow: hidden" @click="utils.a('/expert/space/'+ item.uuid )">
                            <div style="float: left;width: 60px;height: 60px;" class="hand op">
                                <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="item.headIco +'-200x200'">
                            </div>
                            <div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 120px;height: 60px;">
                                <div style="font-weight: bold;margin-top: 10px"><span style="vertical-align: top; line-height: 20px">{{item.nickName}}</span><img src="./imgs/expertIcon.png" style="width: 48px; height: 20px; margin-left: 2px"></div>
<!--                                <div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;"><span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.major && item.address.cityName"> | </span>{{item.major}}</div>-->
                            </div>
                        </div>
                        <div style="color: #999999; text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;" class="hand op" >
                            专家简介：擅长城乡总体规划、详细规划、 各类专项规划、课题研究
                        </div>
                        <el-divider></el-divider>
<!--                        <div style="height: 64px;">-->
<!--                            <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;" @click="utils.a('/expert/info/'+ item.uuid )">-->
<!--                                <div style="margin-left: 6px;color: #666666; width: 210px">-->
<!--                                    <div class="hand op" style="line-height: 30px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden"-->
<!--                                         v-for="ev in item.topics.slice(0,3)" :key="ev.id">#{{ ev.title }}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
<!--                <div style="padding-bottom: 20px;text-align: center;">-->
<!--                    <el-pagination-->
<!--                        background-->
<!--                        @current-change="getExpertList"-->
<!--                        :current-page.sync="hotPageNo"-->
<!--                        :page-size="10"-->
<!--                        layout="prev, pager, next"-->
<!--                        :total="hotTotal">-->
<!--                    </el-pagination>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "team",
        components: {},
        data() {
            return {
                page: 1,
                more: 1,
                pageSize: 30,
                value: '',
                roomInfo: {},

                hotTopicList: [],
                typeList: [],

                expertList: [],
            }
        },

        mounted() {
            this.getHotTopicList();
            this.getExpertList();
            this.getRoomInfo();
            this.getType()
        },

        methods: {
            // 获取行业分类
            getType() {
                this.newApi.getTypeList({lv: 3}).then(res => {
                    this.typeList = res.data
                })
            },

            // 获取room专家团队信息
            getRoomInfo() {
                this.newApi.getRoomInfo({roomId: this.$route.params.id}).then(res => {
                    this.roomInfo = res.data
                })
            },

            // 获取团队专家
            getExpertList() {
                this.newApi.getRoomExpretList({roomId: this.$route.params.id}).then(res => {
                    this.expertList = res.data;
                })
            },

            // 获取专家话题
            getHotTopicList() {
                this.newApi.getRoomExpertTopicList({page: this.page, pageSize: this.pageSize, roomId: this.$route.params.id}).then(res => {
                    this.hotTopicList = res.data;
                    if (res.data.length === 30) {
                        this.page++
                    } else {
                        this.page = 1
                    }
                })
            },

            // 颜色循环
            color(index) {
                var firstColumn = [1, 7, 13, 19, 25];
                var secondColumn = [2, 8, 14, 20, 26];
                var thirdColumn = [3, 9, 15, 21, 27];
                var fourthColumn = [4, 10, 16, 22, 28];
                var fifthColumn = [5, 11, 17, 23, 29];
                var sixthColumn = [6, 12, 18, 24, 30];
                if (firstColumn.indexOf(index) != -1) {
                    return '#3F9FC2'
                } else if (secondColumn.indexOf(index) != -1) {
                    return '#F08A8A'
                } else if (thirdColumn.indexOf(index) != -1) {
                    return '#9298B9'
                } else if (fourthColumn.indexOf(index) != -1) {
                    return '#FF9900'
                } else if (fifthColumn.indexOf(index) != -1) {
                    return '#9298B9'
                } else if (sixthColumn.indexOf(index) != -1) {
                    return '#FF9900'
                }
            },
        }
    }
</script>

<style scoped>
    .team {
        overflow: hidden;
        background-color: rgb(240, 240, 240);
        min-width: 1500px;
    }

    .team .team-body {
        width: 1500px;
        margin: 100px auto;
    }

    .team .team-head {
        width: 1500px;
        height: 260px;
        background-color: #ffffff;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
        padding: 30px 40px;
        box-sizing: border-box;
    }

    .team .column {
        border-radius: 5px;
        background-color: #ffffff;
    }

    .team .column .column-head {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #CECFD0;
        height: 61px;
        line-height: 60px
    }

    .team .column .column-label {
        color: #444444;
        font-weight: 600;
        font-size: 20px;
    }

    .team .column .column-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .team .column .column-btns .fresh {
        margin-left: 30px;
        color: #666666;
        font-size: 16px;
    }

    .team .column .column-btns .input-place {
        width: 200px;
        margin-left: 10px;
    }
</style>
